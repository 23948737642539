/* eslint-disable no-unused-expressions */
import { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import { Row } from '@guestyci/foundation/Layout';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import DateRangePicker from '@guestyci/foundation/DatePicker/DateRangePicker';
import createStyles from '@guestyci/foundation/createStyles';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import t from '@guestyci/localize/t.macro/t.macro';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { CurrencyContext } from 'context/CurrencyContext';
import SearchFilters from 'components/SearchFilters';
import GuestsAndRoomsDropdown, { DropdownOption } from 'components/GuestsAndRoomsDropdown';
import SortBy from 'components/SortBy';
import { getGuestItems } from 'utils/index';
import useGetCities from 'hooks/useGetCities';
import useSearchValues from 'hooks/useSearchValues';
import useDio from 'hooks/useDio';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import { getSortOptions } from 'constants/sortTypes';
import useGetRoomsLabel from 'hooks/useGetRoomsLabel';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 20,
    width: '100%',
    [create('xs')]: {
      flexDirection: 'column',
      padding: 10,
      '& > div': {
        marginRight: 0,
        marginBottom: 10,
      },
    },
    [create('lg')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      '& > div': {
        marginRight: 5,
      },
    },
    [create('xl')]: {},
  },
  cityDropdown: {
    '& .text-field-wrapper': {
      color: '#949FA8',
    },
    '& [class^="TextField-overflowEllipsis"]': {
      color: '#212121',
    },
    [create('xs')]: {
      width: '100%',
    },
    [create('lg')]: {
      width: 200,
    },
    [create('xl')]: {
      width: 250,
    },
  },
  dateRangePicker: {
    [create('xs')]: {
      width: '100%',
      '& .DateRangePicker_picker': {
        left: '-75px!important',
      },
    },
    [create('lg')]: {
      width: 300,
    },
    [create('xl')]: {
      width: 420,
    },
  },
  submitButton: {
    fontSize: '18px',
    [create('xs')]: {
      marginLeft: 0,
      marginTop: 10,
    },
    [create('md')]: {
      marginLeft: 10,
      marginTop: 10,
    },
    [create('lg')]: {
      marginTop: 0,
    },
  },
  guestsDropdown: {
    background: '#fff',
    '& .text-field-wrapper': {
      color: '#949FA8',
    },
    '& [class^="TextField-overflowEllipsis"]': {
      color: '#212121',
    },
    [create('xs')]: {
      width: '100%',
    },
    [create('lg')]: {
      width: 200,
    },
    [create('xl')]: {
      width: 280,
    },
  },
}));

export const GuestsFilterByFeatureToggle = ({
  selectedGuestsCount,
  selectedRoomsCount,
  handleSetGuestsCount,
  handleSetRoomsCount,
}) => {
  const { isRoomLabel } = useGetRoomsLabel();
  const { guestsDropdown } = useStyles();
  const { contentConfiguration: { maxGuests } = {}, displayOptions: { shouldShowGuestsFilter } = {} } =
    useContext(WebsiteSettingsContext);
  const [, isEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  if (!shouldShowGuestsFilter) {
    return null;
  }

  const guests = getGuestItems(maxGuests);
  const label = isRoomLabel ? t('Rooms') : t('Units');

  if (isEnabled) {
    return (
      <GuestsAndRoomsDropdown className={guestsDropdown}>
        <DropdownOption
          min={selectedRoomsCount}
          max={maxGuests}
          value={selectedGuestsCount}
          handleChange={handleSetGuestsCount}
          label={t('Guests')}
        />
        <DropdownOption
          min={1}
          max={10}
          value={selectedRoomsCount}
          handleChange={handleSetRoomsCount}
          label={label}
          onChange={(value) => {
            if (value > selectedGuestsCount) {
              handleSetGuestsCount({ target: { value } });
            }
          }}
        />
      </GuestsAndRoomsDropdown>
    );
  }

  return (
    <Dropdown
      className={guestsDropdown}
      placeholder={t('Guests')}
      value={selectedGuestsCount}
      onChange={handleSetGuestsCount}
    >
      {guests?.map((guest) => (
        <Option value={guest.value} key={guest.value}>
          {guest.label}
        </Option>
      ))}
    </Dropdown>
  );
};

const SearchBar = ({ onSubmit, withAdditionalFilters = false, totalListings = 0 }) => {
  const { selectedCurrency, defaultCurrency } = useContext(CurrencyContext);
  const { dioTrack } = useDio();
  const { startDate, endDate, city, country, minOccupancy, rooms, sortOrder, sortBy } = useSearchValues();
  const [dateRange, setDateRange] = useState({ startDate, endDate });
  const [selectedLocation, setSelectedLocation] = useState({ city, country });
  const [filterParams, setFilterParams] = useState({});
  const { displayOptions: { shouldShowCityFilter } = {}, contentConfiguration: { searchSettings } = {} } =
    useContext(WebsiteSettingsContext);
  const { sortSettings: { value: sortData, active: activeSort = true } = {} } = searchSettings;
  const [, isGroupReservationEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  const { cityDropdown, dateRangePicker, root, submitButton } = useStyles();

  const [searchValue, setSearchValue] = useState();
  const [selectedGuestsCount, setSelectedGuestsCount] = useState(minOccupancy || undefined);
  const [selectedRoomsCount, setSelectedRoomsCount] = useState(rooms || 1);
  const allSortOptions = getSortOptions();
  const sortOptions = sortData?.map((option) => allSortOptions[option]) || [];
  const defaultSortOrder = sortOptions.find((option) => option.sortBy === sortBy && option.sortOrder === sortOrder);
  const [selectedSortOrder, setSelectedSortOrder] = useState(defaultSortOrder);

  const { data: cities, isLoading: areCitiesLoading } = useGetCities({ searchValue });

  const handleSearch = debounce((e) => {
    const { value } = e.target;
    setSearchValue(value);
  }, 250);

  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };

  const handleSetCity = (e) => {
    const { value } = e.target;
    setSelectedLocation(value);
  };

  const handleSetGuestsCount = (e) => {
    const { value } = e.target;
    setSelectedGuestsCount(value);
  };

  const handleSetRoomsCount = (e) => {
    const { value } = e.target;
    setSelectedRoomsCount(value);
  };

  const handleSubmit = () => {
    const searchParams = {
      dates: dateRange,
      location: selectedLocation || {},
      ...(selectedGuestsCount && { guests: selectedGuestsCount }),
      ...(selectedRoomsCount && isGroupReservationEnabled && { rooms: selectedRoomsCount }),
      ...(selectedSortOrder && { ...pick(selectedSortOrder, ['sortBy', 'sortOrder']) }),
      ...filterParams,
      currency: selectedCurrency || defaultCurrency,
    };
    const nightsCount = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');
    dioTrack('click_search_bar', 'click', {
      city: selectedLocation?.city,
      county: selectedLocation?.country,
      guests: selectedGuestsCount,
      num_night: nightsCount,
      ...(selectedRoomsCount && isGroupReservationEnabled && { rooms: selectedRoomsCount || 1 }),
    });
    onSubmit(searchParams);
  };

  useEffect(() => {
    if (withAdditionalFilters) handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortOrder]);

  return (
    <>
      <Row data-search-bar justify="center" className="pt-4 pb-4">
        <div>
          <div className={root}>
            {/*  TODO: move city search to separate component */}
            {shouldShowCityFilter && (
              <Dropdown
                placeholder={t('Select destination')}
                value={selectedLocation?.city}
                onChange={handleSetCity}
                className={cn('bg-white mr-1', cityDropdown)}
                searchable
                onSearchQueryChange={handleSearch}
                isLoading={areCitiesLoading}
              >
                {cities?.map((data) => (
                  <Option
                    data-qa="destination-dropdown"
                    value={data}
                    key={`${data.city}-${data.country}-${data.state}`}
                  >
                    {data.city}
                  </Option>
                ))}
              </Dropdown>
            )}
            <DateRangePicker
              className={cn(dateRangePicker, 'bg-white')}
              value={dateRange}
              onChange={(value) => handleDateRangeChange(value)}
              startDatePlaceholderText={t('Start date')}
              endDatePlaceholderText={t('End date')}
            />
            <GuestsFilterByFeatureToggle
              selectedGuestsCount={selectedGuestsCount}
              selectedRoomsCount={selectedRoomsCount}
              handleSetGuestsCount={handleSetGuestsCount}
              handleSetRoomsCount={handleSetRoomsCount}
            />
            <button type="button" onClick={handleSubmit} className={cn('btn btn-colored', submitButton)}>
              {t('Search')}
            </button>
          </div>
          {withAdditionalFilters && <SearchFilters totalListings={totalListings} params={setFilterParams} />}
        </div>
      </Row>
      {withAdditionalFilters && (
        <SortBy
          setSelectedSortOrder={setSelectedSortOrder}
          selectedSortOrder={selectedSortOrder}
          sortOptions={activeSort && sortOptions}
        />
      )}
    </>
  );
};

export default SearchBar;
