import axios from 'axios';

// TODO: provide our own service to get user location
const URL = 'https://get.geojs.io/v1/ip/country.json';

const getUserLocation = async () => {
  const res = await axios.get(URL);

  return {
    ...res.data,
    country_code: res?.data?.country,
    IPv4: res?.data?.ip,
  }
};

export default getUserLocation;
